body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.c-primario{
  color: #ef7917 !important;
}
.c-secundario{
  color: #081072 !important;
}
.btn-primary{
  background-color: #ef7917 !important;
  border-color: #ef7917 !important;
  color:white;
  font-weight: 600;
}
.btn-primary:hover{
  background-color: #ef7917 !important;
  border-color: #ef7917 !important;
  color:white;
  font-weight: 600;
}
@media(max-width:1024px){
  .escritorio-menu{
    display:none !important;
  }
  .mobil-menu{
    display: block !important;
  }
  .mobil-menu .react-tabs__tab-list{
    position: absolute;
    width: 60%;
    z-index: 99;
    position: fixed;
  }
  .quitar{
    display: block !important;
    position: absolute;
    top: 7px;
    left: 63%;
    position: fixed;
  }
  .hamburger{
    display: block !important;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .Panel-mobil{
    width: 100%;
  }
  .react-tabs__tab-panel{
    width: 100vw !important;
    margin-left: 0px !important;
  }
}
.hamburger{
  display: none;
}
.hamburger i{
  font-size: 30px;
  color: #ef7917;
}
.quitar{
  display: none;
}
.quitar i{
  font-size: 30px;
  font-weight: 700;
  color: #ef7917;
  cursor: pointer;
}
.mobil-menu{
  display: none;
  min-height: 100vh;
}
.escritorio-menu{
  min-height: 100vh;
  position: fixed;
}
.react-tabs {
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 0;
  padding: 0;
  color: white;
  background: #3c3e43;
  min-height: 100vh;
}
.react-tabs__tab {
  height: 50px;
  list-style: none;
  padding: 12px 0px;
  padding-left: 15px;
  cursor: pointer;
  color: #bbb;
  font-weight: 700;
}
.react-tabs__tab--selected {
  background: #393f8d;
  border-color: #1c1d1f;
  border-right: 10px solid #ef7917;
  color: white;
}
.react-tabs__tab-panel  {
  width: calc(100vw - 200px);
  margin-left: 200px;
}
.logo{
width: 80%;
margin-left: 10%;
display: block;
margin-top: 30px;
margin-bottom: 50px;
}
.panel-content{
  text-align: end;
}
.card {
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, .125);
  border-radius: 0.428rem;
  overflow: hidden;
  transition: all .3s ease-in-out,background 0s,color 0s,border-color 0s;
}
.cardd{
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, .125);
  border-radius: 0.428rem;
  transition: all .3s ease-in-out,background 0s,color 0s,border-color 0s;
  padding: 1rem;
}
.card:hover{
  transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}
.editar-borrar {
  width: 140px;
  display: flex;
  justify-content: space-between;
}
.nombre-card{
  width: calc(100% - 150px);
  overflow: hidden;
  display: flex;
  align-items: center;
}
.nombre-card p{
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0%;
  font-weight: 700;
}
.Modal{
  width: 100%;
  padding-top: 20px;
  width: 80vw;
  height: 80vh;
}
.Modalfaltaselect{
  width: 400px;
  height: 40px;
}
.cerrarmodal{
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.cerrarmodal{
  font-size: 25px;
  color: #ef7917;
}
.img-ver{
  width: 50%;
  margin: auto;
  height: auto;
  display: block;
}
.verModal h2{
  padding: 0px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.verModal p{
  padding: 0px 20px;
  text-align: justify;
}
.verEstadistica{
  width: 50px;
}
.nombre-card-estadistica{
  width: calc(100% - 60px);
  overflow: hidden;
  display: flex;
  align-items: center;
}
.nombre-card-estadistica p{
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0%;
  font-weight: 700;
}
.login-app{
  width: 100vw;
}
.cerrarSesion{
  height: 50px;
  list-style: none;
  padding: 12px 0px;
  padding-left: 15px;
  cursor: pointer;
  color: #bbb;
  font-weight: 700;
}
.vertical nav{
	position:fixed;
	top:0;
	left:0;
	display:flex;
	flex-direction:column;
	justify-content: space-between;
	width:15%;
	min-height: 100vh;
	background-color: #1d293e;
	padding:2rem 1rem 1rem 1rem;
	transition: transform 1s;
}

.vertical .navbar{
	transform: translateX(-100%);
}

.vertical .nav-btn{
	position: absolute;
	transform: translateX(38px);
	top: 20px;
	right: 0;
	width:40px;
	height: 60px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #1d293e;
	outline: none;
	border: none;
	font-size: 1rem;
	padding: 5px 10px;
	cursor: pointer;
	color: #fff;
}

.vertical .logo{
	display: block;
	width: 100px;
	margin: auto;
	background:transparent;
}

.vertical .logo img{
	display: block;
	width: 100%;
	height: 100%;
}

.vertical .nav-link{
	display: flex;
	align-items: center;
	color: #fff;
	text-decoration: none;
	padding: 10px 1rem;
	border-radius: 5px;
	margin-bottom: 5px;
}

.vertical .nav-link:hover{
	background-color:#ff5a5f;
	color: #1d293e;
}
.vertical .btn:hover{
	background-color:#ff5a5f;
	color: #1d293e;
}

.vertical .btn{
	display: flex;
	align-items: center;
	color: #fff;
	text-decoration: none;
	padding: 10px 1rem;
	border-radius: 5px;
	margin-bottom: 5px;
}

.vertical .nav-link span{
	margin-left: 10px;
}

.vertical .nav-top{
	margin-top: 10rem;
}

.vertical .mobile-nav{
	background-color:#753ffd;
	width: 100%;
	height:40px;
	display: none;
}

.vertical .mobile-nav-btn{
	color: #fff;
	background: transparent;
	outline: none;
	border: none;
	margin: 0 10px;
}



@media only screen and (max-width: 768px){
	.vertical nav{
		width: 40%;
		padding: 10px;
	}
}

@media only screen and (max-width: 450px){
	.vertical .mobile-nav{
		display: flex;
		align-items: center;
	}
	.vertical nav{
		width: 70%;
		top: 38px;
		min-height: calc(100vh - 38px);
		z-index: 99;
	}

	.vertical .nav-btn{
		display: none;
	}
}
.wrapper {
  background-color: #f7f7f7;
  min-height: 100vh;
}

.page{
	flex-basis: 100%;
	transition: margin-left 1s;
}

.page-with-navbar{
	margin-left: 15%;
}

@media only screen and (max-width: 768px){
	.page-with-navbar{
		width: 100%;
		margin-left: 0;
	}

}

.borderItinerario{
  border: 1px solid #081072;
  border-radius: 10px;
}

.desicion{
  display: flex;
  background-color: #fff;
  width: 170px;
  height: 40px;
  justify-content: space-around;
  align-items: center;
  margin:auto;
  border-radius: 5px;
}
.desicion .cajita{
  width: 45%;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.desicion .cajita a{
  text-decoration: none;
  color: #000;
}
.bg-gris{
  background-color: #b6b6b6;
  color: #fff !important;
}
.Loading{
  position: fixed;
  width: 85%;
  height: 100vh;
  background-color: white;
  top:0;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 9;
}

.cargando{
  position: relative;
  color: #333;
  font-weight: bold;
  font-size: 3.3em;
  font-family: consolas;
}
.cargando::before{
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #ef7917;
  animation: lod 2s linear infinite;
  overflow: hidden;
  height: 100%;
}

.head-table{
  width: 100%;
  height: 40px;
  background-color: #ef7917;
  display: flex;
  border-radius: 10px 10px 0 0;
}

.body-table{
  width: 100%;
}
.body-table div{
  width: 100%;
  height: 50px;
  display: flex;
}

.numero-table{
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
}

.nombre-table{
  width: 60%;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 600;
}

.accion-table{
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
}

.body-table div .numero-table-hijo{
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.body-table div .nombre-table-hijo{
  width: 60%;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.body-table div .accion-table-hijo{
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}


@keyframes lod {
  0%{
    width: 0%;
  }
  100%{
    width: 100%;
  }
}

.lista-categorias{
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lista-categorias p{
font-weight: 600;
}
.lista-categorias-x{
  font-weight: bold !important;
  font-size: 20px;
  margin-right: 5px !important;
  color: #ef7917;
}

.lista-categorias:hover p{
  color: #ef7917;
}